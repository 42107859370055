<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col lg="9" md="10" sm="12">
                <b-card class="mb-2">
                    <div class="d-flex justify-content-end">
                        <a :href="qrCode" :download="`QR Code ${this.asset.nama}`" class="btn btn-info">Download</a>
                    </div>
                    <div class="w-100 d-flex flex-column" id="qrcode-container" v-if="asset.qrcode">
                        <div class="d-flex justify-content-center">
                            <img :src="asset.qrcode" class="d-block mb-1" alt="QR Code" style="width: 150px;" />
                        </div>
                    </div>
                    <div v-if="asset.qrcode && qrCode" class="d-flex justify-content-center mb-3">
                        <b-button variant="info" @click.prevent="printQrCode">Cetak</b-button>
                    </div>
                    <table class="table">
                        <tr>
                            <td>Nama Aset</td>
                            <th>{{ asset ? asset.nama : '' }}</th>
                        </tr>
                        <tr>
                            <td>Kode</td>
                            <th>{{ asset ? asset.kode : '' }}</th>
                        </tr>
                        <tr>
                            <td>Jenis Aset</td>
                            <th>
                                <div v-if="asset">
                                    {{ asset.jenis == 1 ? 'TETAP' : 'BHP' }}
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <td>Kategori</td>
                            <th>
                                <div v-if="asset">
                                    {{ asset.kategori ? asset.kategori.kategori : '' }}
                                </div>
                            </th>
                        </tr>
                        <!-- <tr>
                            <td>Umur</td>
                            <th>{{ asset ? asset.umur : '' }}</th>
                        </tr> -->
                        <tr>
                            <td>Volume</td>
                            <th>{{ asset ? asset.volume : '' }}</th>
                        </tr>
                        <tr>
                            <td>Harga Dasar </td>
                            <th>Rp {{ asset ? formatRupiah(asset.harga_dasar) : 0 }}</th>
                        </tr>
                        <tr>
                            <td>Keterangan</td>
                            <th>{{ asset ? asset.keterangan : '' }}</th>
                        </tr>
                        <tr>
                            <td>Stok</td>
                            <th>{{ asset && asset.stok ? (asset.stok.penyimpanan + asset.stok.peminjaman) : '' }}</th>
                        </tr>
                        <tr>
                            <td>Satuan</td>
                            <th>
                                <div v-if="asset">
                                    {{ asset.satuan ? asset.satuan.satuan : '' }}
                                </div>
                            </th>
                        </tr>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BButton, BOverlay, BRow, BCol, BCard} from 'bootstrap-vue'
export default {
    components: {
        BButton, BOverlay, BRow, BCol, BCard
    },
    data: () => ({
        loading: false,
        asset: {},
        qrCode: null
    }),
    methods: {
        async printQrCode() {
            const asset_id = this.$route.params.id
            this.loading = true
            const response = await this.$store.dispatch('asset/printQRCode', asset_id)
            this.loading = false
            window.open(URL.createObjectURL(response))
        },
        async downloadQRCode() {
            const response = await fetch(this.asset.qrcode)
            const blob = await response.blob()
            
            window.open();
        },
        async getAsset() {
            this.loading = true
            let asset = this.$store.getters['asset/searchBarang'](this.$route.params.id)
            if(!asset) {
                asset = await this.$store.dispatch('asset/getBarangById', this.$route.params.id)
            }

            this.asset = asset
            if(this.asset.qrcode) {
                const response = await fetch(this.asset.qrcode)
                const blob = await response.blob()
                this.qrCode = URL.createObjectURL(blob)
            }
            this.loading = false
        }
    },
    created() {
        if(!this.$route.params.id) {
            this.$router.push('/asset')
        }
        this.getAsset()
    }
}
</script>
<style>
#qrcode-container {
    width: 100px;
}
</style>